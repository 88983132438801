<template>
    <div class="map l-container">
        <el-amap
                class="amap-box"
                :vid="'amap-vue'"
                :zoom="14"
                :plugin="plugin"
                :center="mapCenter"
                :events="mapEvents"
                ref="map"
        >
            <el-amap-marker
                    :vid="item.id"
                    v-for="item in markList"
                    :key="item.id"
                    :position="item.location"
                    :extData="item"
                    :draggable="false"
                    :raiseOnDrag="false"
                    :events="markEvents"
                    :animation="item.id == currentId ? 'AMAP_ANIMATION_BOUNCE' : 'AMAP_ANIMATION_NONE'"></el-amap-marker>
        </el-amap>
        <div class="map_body">
            <div class="map_body_head l-flex-s0">
                <searchV1
                        v-model="keyword"
                        style="width: 100%; height: 100%"
                        placeholder="请输入您需要的地点"
                        @search="search"
                ></searchV1>
            </div>
            <div class="map_body_content" v-if="mapList.length>0">
                <scrollview @onRefresh="onRefresh" @onReload="onReload">
                    <div
                            class="map_body_content_item"
                            :class="{ 'map_body_content_item-active': item.id == currentId }"
                            v-for="item in mapList"
                            :key="item.id"
                            @click="changeSelect(item)"
                    >
                        <div>
                            <p>{{ item.site_name }}</p>
                            <p>{{ item.distance }}以内｜{{ item.address }}</p>
                        </div>
                        <img
                                v-if="currentId == item.id"
                                :src="require('@STATIC/image/icons/success-orange.png')"
                        />
                    </div>
                </scrollview>
            </div>
            <!--<div style="height: 100px"></div>-->
            <!--<div class="map_body_foot">
                <button @click="onCancel">取消</button>
                <button @click="onConfirm">确定</button>
            </div>-->
        </div>
    </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import searchV1 from '@T/community/search/searchV1'
  // import Amap from "@amap/amap-vue/lib/amap";
  export default {
    components: {
      searchV1,
    },
    data () {
      return {
        mapType: 1,
        couponSelectSitePoint: 0,
        defaultCenter: [117.07822, 39.98246],
        keyword: '', //搜索关键词
        currentId: 0,
        mapCenter: [117.07822, 39.98246], //地图中心坐标数组 所以初始为空
        loaded: false,
        /*events是包含地图事件的对象，init是地图初始化时触发，moveeend地图拖动时触发，click点击地图触发，zoomchange地图缩放级别更改后触发*/
        // events: {
        //   // init: this.mapInit,
        //   // moveend: this.moveend,
        //   // zoomchange: this.zoomchange,
        //   complete: this.complete,
        // },
        mapEvents: {
          complete: this.mapComplete,
        },
        markEvents: {
          //标记事件
          init: this.initMark,
          click: this.markClick, //标记点击触发
          show: this.show,
        },
        plugin: [
          'Autocomplete',
          'PlaceSearch',
          'Scale',
          'OverView',
          'PolyEditor',
        ],
        cityInfo: {}, //当前城市信息
        mapList: [], //所有站点信息
        ctxObj: {},
        params: {
          page: 1,
          page_size: 10,
        },
        goods_id: 0,
        spec_ids: '',
        orderAmount:0
      }
    },
    computed: {
      markList () {
        let list = []
        if (this.mapList.length > 0) {
          list = this.mapList.map((val) => {
            let location = val.location.split(',').reverse()
            return {
              id: val.id,
              title: val.site_name,
              location: location,
            }
          })
        }
        //console.log(this.mapList,'computed')

        return list
      },
    },
    mounted () {
      document.title = '选择地址'
      this.defaultCenter = [
        this.common_platform.params?.vuex_localAddressLL.lng,
        this.common_platform.params?.vuex_localAddressLL.lat,
      ]

      this.mapCenter = this.defaultCenter

      this.currentId = this.$route.query.site_point_id
      this.mapType = this.$route.query.mapType
      this.couponSelectSitePoint = this.$route?.query?.couponSelectSitePoint || 0
      this.goods_id = this.$route?.query?.goods_id || 0
      this.spec_ids = this.$route?.query?.spec_ids || ''
      this.number = this.$route?.query?.number || 0
      this.orderAmount = this.$route?.query?.orderAmount || 0
      setTimeout(() => {
        this.getMapList()
      },300)
    },
    watch: {
      /*markList () {
        this.mapComplete()
      },*/
    },
    methods: {
      ...mapActions([
        'community_order/setPointId',
        'community_order/siteCouponSitePoint',
        'coupon_card_card/setExpressPointInfo'
      ]),
      // ...mapActions('community_order', ['setPointId']),
      // ...mapActions('coupon_card_card', ['setExpressPointInfo']),
      onRefresh (done) {
        this.mapCenter = this.defaultCenter
        this.list = []
        this.mapList = []
        this.ctxObj = {}
        this.params.page = 1
        this.getMapList().finally(() => {
          done()
        })
      },
      /**
       * step 当前加载结束
       * over 没有更多数据了
       */
      onReload (step, over) {
        console.log('上拉加载')
        this.params.page += 1
        this.getMapList().finally(() => {
          if (this.mapList.length < this.params.page * this.params.page_size) {
            over()
          } else {
            step()
          }
        })
      },
      //获取自提点列表
      async getMapList () {
        try {
          let position = [...this.mapCenter]
          position = position.reverse().join(',')
          let query = await this.$api.community.order.getMapList({
            keyword: this.keyword,
            location: position,
            page: this.params.page,
            page_size: 10,
            goods_id: this.goods_id,
            spec_ids: this.spec_ids,
            number: this.number,
            orderAmount:this.orderAmount
          })
          if (query.data.length == 0) {
            return
          }
          this.mapList = [...this.mapList, ...query.data]
          // let hasId = this.mapList.filter((val) => val.id == this.currentId);
          // if (hasId.length == 0) {
          //   this.currentId = query.data[0].id;
          // }
          let item = this.markList.find((val) => val.id == this.currentId)
          this.mapCenter = item?.location || this.defaultCenter
        } catch (error) {
          //
        }
      },
      //定位初始化
      getGolocationInit (o) {
        // o.getCurrentPosition((status, result) => {
        //   if (result && result.position) {
        //     let { position, addressComponent, formattedAddress } = result;
        //     let { lng, lat } = position;
        //     this.loaded = true;
        //     this.location = [lng, lat];
        //     this.mapCenter = [lng, lat];
        //     this.markPosition = [lng, lat];
        //     console.log("lng,lat", lng, lat);
        //     console.log("addressComponent", addressComponent);
        //     console.log("formattedAddress", formattedAddress);
        //   }
        // });
      },
      // 地图初始化函数
      mapInit (o) {
        // console.log(o.getCenter());
        // console.log(this.$refs.map.$$getInstance());
        // o.getCity((result) => {
        //   this.cityInfo = result;
        // });
      },
      setMarkerIcon (ctx, active = false) {
        if (!ctx) {
          return
        }
        let icon = new AMap.Icon({
          size: new AMap.Size(24, 24), // 图标尺寸
          image: active
            ? require('@STATIC/image/icons/address-orange.png')
            : require('@STATIC/image/icons/address-blue.png'), // Icon的图像
          imageSize: new AMap.Size(24, 24), // 根据所设置的大小拉伸或压缩图片
        })
        ctx.setIcon(icon)
      },
      markClick (e) {
        // console.log("markClick", e, e.target.getExtData());
        let oldCtx = ''
        if (Object.keys(this.ctxObj).length > 0 && this.ctxObj[this.currentId]) {
          oldCtx = this.ctxObj[this.currentId].ctx
          this.setMarkerIcon(oldCtx)
        }

        let ctx = e.target
        this.setMarkerIcon(ctx, true)
        let {lng, lat} = ctx.getPosition()
        this.mapCenter = [lng, lat]
        this.currentId = ctx.getExtData().id
      },
      initMark (ctx) {
        let item = ctx.getExtData()
        let ctxObj = this.ctxObj
        Object.assign(ctxObj, {
          [item.id]: {
            ctx,
            item,
          },
        })
        this.setMarkerIcon(ctx)
      },
      show () {
        console.log('show')
      },
      mapComplete () {
        setTimeout(() => {
          let currentCtx = ''
          if (Object.keys(this.ctxObj).length == 0) {
            return
          }
          if (this.ctxObj[this.currentId]) {
            currentCtx = this.ctxObj[this.currentId].ctx
          }
          this.setMarkerIcon(currentCtx, true)
        }, 100)
      },
      changeSelect (item) {

        let params = {
          address: item.address,
          site_name: item.site_name,
          site_point_id: item.id,
        }
        if (this.mapType == 1) {
          if (this.couponSelectSitePoint == 1) {
            this['community_order/siteCouponSitePoint'](params)
          } else {
            this['community_order/setPointId'](params)
          }
        } else {
          this['coupon_card_card/setExpressPointInfo'](params)
        }
        this.$router.back()

        /*if (Object.keys(this.ctxObj).length > 0 && this.ctxObj[this.currentId]) {
          let oldCtx = this.ctxObj[this.currentId].ctx
          this.setMarkerIcon(oldCtx)
        }
        let newCtx = this.ctxObj[item.id].ctx
        let {lng, lat} = newCtx.getPosition()
        this.setMarkerIcon(newCtx, true)
        this.mapCenter = [lng, lat]
        this.currentId = item.id*/
      },
      //地图移动
      moveend () {},
      //地图比例改变
      zoomchange () {},
      onCancel () {
        this.$router.back()
      },
      onConfirm () {
        /*let item = this.mapList.find((val) => val.id == this.currentId)
        let params = {
          address: item.address,
          site_name: item.site_name,
          site_point_id: item.id,
        }
        if (this.mapType == 1) {
          this['community_order/setPointId'](params)
        } else {
          this['coupon_card_card/setExpressPointInfo'](params)
        }
        this.$router.back()*/
      },
      //搜索
      search () {

        this.mapList = []
        this.ctxObj = {}
        this.params.page = 1
        this.mapCenter = this.defaultCenter
        this.getMapList()
      },
    },
  }
</script>

<style scoped lang="scss">
    @include b(map) {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        @include b(amap-box) {
            position: relative;
            width: 100%;
            height: 267px;
            flex-shrink: 0;
        }
        @include e(body) {
            flex: 1;
            border-radius: 34px 34px 0px 0px;
            bottom: 0;
            padding: 15px;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            @include e(head) {
                width: 100%;
                height: 36px;
                flex-shrink: 0;

            }
            @include e(content) {
                flex: 1;
                width: 100%;
                overflow-y: scroll;

                @include e(item) {
                    width: 100%;
                    height: 47px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #ebebeb;
                    & > div {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        & > p {
                            width: 100%;
                            box-sizing: border-box;
                            margin: 0;
                            @include ellipsis(1);
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #939393;
                            &:first-of-type {
                                color: #333333;
                                margin-bottom: 9px;
                            }
                        }
                    }

                    & > img {
                        flex-shrink: 0;
                        width: 21px;
                        height: 21px;
                    }
                    @include m(active) {
                        & > div {
                            width: calc(100% - 30px);
                        }
                    }
                }
            }
            @include e(foot) {
                flex-shrink: 0;
                width: 100%;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 50px;
                & > button {
                    border: none;
                    outline: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 166px;
                    height: 44px;
                    background: #ffece1;
                    border-radius: 3px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #f46a17;
                    &:last-of-type {
                        background: linear-gradient(
                                        125deg,
                                        #fab476 0%,
                                        #eb6100 100%,
                                        #eb6100 100%
                        );
                        color: #ffffff;
                    }
                }
            }
        }
    }
</style>
